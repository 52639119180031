$grid-max-width: 1440 / 16 * 1rem; // express in rems for easier calculations

$color-navigation-background: #333;
$color-navigation-background--hover: darken($color-navigation-background, 3%);

$color-brand: #e95420;
$color-light-new: #e5e5e5;
$color-navigation-active-bar: $color-brand;

$breakpoint-x-large: 1300px;
$breakpoint-navigation-threshold: 870px;
$increase-font-size-on-larger-screens: false;

// XXX Caleb 02/06/2020 Sidenav looks disabled on #f7f7f7 background.
// Remove when Vanilla updated >=2.12.1
// https://github.com/canonical-web-and-design/vanilla-framework/issues/3105
$colors--light-theme--background-highlight: rgba(#111, 0.03);
$colors--light-theme--border-default: rgba(#111, 0.2);
$colors--light-theme--border-high-contrast: rgba(#111, 0.56);
$colors--light-theme--border-low-contrast: rgba(#111, 0.1);

$multi: 1;
