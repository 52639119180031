// Import settings
@import "./settings";

// Import and include base Vanilla
@import "vanilla-framework/scss/build";

.root {
  background-color: $color-light;
  height: 100vh;
}

.root-loading {
  @extend %vf-has-box-shadow;
  background-color: $color-x-light;
}
